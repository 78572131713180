//引入react jsx写法的必须
import React, {Suspense, useEffect, useState} from 'react';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
// 引入路由配置
import { routes } from "./config";
//引入一些模块
import {Routes, Route} from "react-router-dom";
import ELoading from "../components/CommonComponents/ELoading/ELoading";
import {fromShare} from "../api/apis";
import {getParams} from "../plugins/constantFunction";

function Router(){
  const url = useLocation();
  const navigate = useNavigate();

  const [urlParams, changeUrlParams] = useState(getParams(url.search));
  useEffect(() => {
    // 从太行启翼菜单，固定qiyip1存放token
    if(urlParams && urlParams.qiyip1) {
      window.localStorage.setItem("ecube_accessToken", urlParams.qiyip1)
      navigate("/", { replace: true })
    }

    // 从已发布链接进入，在跳转链接上带上固定shareUid，此时不需要token
    if(urlParams && urlParams.shareUid) {
      fromShare({shareUid: urlParams.shareUid}).then(res => {
        if(res.status === 0) {
          if(res.result === 1) {
            navigate(`/project/screen1/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          } else if(res.result === 2) {
            navigate(`/project/screen2/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          } else if(res.result === 3) {
            navigate(`/project/screen3/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          } else if(res.result === 4) {
            navigate(`/project/screen4/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          } else if(res.result === 5) {
            navigate(`/project/screen5/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          } else if(res.result === 6) {
            navigate(`/project/screen6/fromShare?shareUid=${urlParams.shareUid}`, { replace: true })
          }
        }
      })
    }
  }, [])

  return (
    <Routes>
      {
        routes.map(router => {
          return (
            <Route
              key={router.name}
              path={router.path}
              element={
                // <Suspense fallback={<div>正在加载...</div>}>
                <Suspense fallback={<ELoading />}>
                  <router.component />
                </Suspense>
              }
            >
            </Route>
          )
        })
      }
      <Route path="/" element={ <Navigate replace to="/home" />  } />
    </Routes>
  );
}

export default Router;
